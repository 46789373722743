// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Utilities
import { generateHtmlIdForLabel } from '../../utils/helpers';

// Components
import Radio from '../Radio';

export class RadioGroup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: props.initialValue,
    };
  }

  getOptionClickHandler = (id) => () => {
    const { onChange } = this.props;
    const { selectedOption } = this.state;

    if (selectedOption !== id) {
      this.setState({
        selectedOption: id,
      });

      if (onChange) {
        onChange(id);
      }
    }
  };

  renderOption = ({ id, label }) => {
    const { selectedOption } = this.state;
    const { t } = this.props;
    const htmlId = generateHtmlIdForLabel(`${label}-${id}`);

    return (
      <div key={id} className="radio">
        <label htmlFor={htmlId} className="radio__control-label is-primary-color">
          <Radio
            id={htmlId}
            checked={selectedOption === id}
            onChange={this.getOptionClickHandler(id)}
          />
          <div className="radio__control-display" />
          {t(label, { defaultValue: label })}
        </label>
      </div>
    );
  };

  render() {
    const { options } = this.props;

    return (
      <div className="radio-group" role="group" tabIndex={0}>
        {options.map(this.renderOption)}
      </div>
    );
  }
}

RadioGroup.propTypes = {
  t: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
};

RadioGroup.defaultProps = {
  initialValue: null,
  onChange: null,
};

export default withTranslation()(RadioGroup);
