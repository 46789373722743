// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Option = ({ id, label, selected, onClick }) => (
  <div className={classNames('select-input-option', { selected })}>
    <input
      type="radio"
      id={`select-option-${id}`}
      aria-label={label}
      aria-checked={selected}
      checked={selected}
      onChange={onClick}
    />
    <label htmlFor={`select-option-${id}`} className="is-secondary-color is-primary-outline">
      {label}
    </label>
  </div>
);

Option.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Option;
