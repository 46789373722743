// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import YoutubeEmbedder from 'common/components/YoutubeEmbedder';

const Card = ({ label, imageUrl, youtubeId, selected, onClick }) => (
  <div className={classNames('checkbox-cards-input-card', 'card', { selected })} onClick={onClick}>
    {imageUrl && (
      <div className="card-image">
        <figure className="image is-4by3">
          <img src={imageUrl} draggable={false} alt="" />
        </figure>
      </div>
    )}
    {youtubeId && <YoutubeEmbedder id={youtubeId} />}
    <div className="card-content">
      <div className="content">
        <span className="label is-primary-color">{label}</span>
        <div className="checkbox">
          <i className="fas fa-check" />
        </div>
      </div>
    </div>
  </div>
);

Card.propTypes = {
  label: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  youtubeId: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

Card.defaultProps = {
  imageUrl: null,
  youtubeId: null,
};

export default Card;
