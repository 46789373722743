// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Components
import Card from './Card';

// Constants
const SPACE = 'Space';

class CardSelectInput extends PureComponent {
  state = {
    selectedOptions: new Set(),
    cardId: null,
  };

  componentDidMount() {
    const { initialValue } = this.props;
    this.setState({ selectedOptions: new Set(initialValue) });
  }

  addKeyDownListener = (id) => {
    this.setState({ cardId: id });
    document.addEventListener('keydown', this.handleKeyDown);
  };

  removeKeyDownListener = () => {
    this.setState({ cardId: null });
    document.removeEventListener('keydown', this.handleKeyDown);
  };

  handleKeyDown = (event) => {
    const { cardId } = this.state;
    if (event.code === SPACE) {
      event.preventDefault();
      this.getCardClickHandler(cardId);
    }
  };

  getCardClickHandler = (id) => {
    const { multipleSelection, onChange } = this.props;
    const { selectedOptions } = this.state;
    const isSelected = selectedOptions.has(id);

    if (!multipleSelection) {
      selectedOptions.clear();
    }

    if (isSelected) {
      selectedOptions.delete(id);
    } else {
      selectedOptions.add(id);
    }

    const newSelectedOptions = new Set(selectedOptions);

    this.setState({
      selectedOptions: newSelectedOptions,
    });

    if (onChange) {
      onChange([...newSelectedOptions]);
    }
  };

  render() {
    const { options, multipleSelection } = this.props;
    const { selectedOptions } = this.state;

    return (
      <div className="card-select-input">
        <div className="columns">
          {options.map(({ id, label, imageUrl, youtubeId }) => (
            <div
              tabIndex={0}
              className="column"
              key={id}
              aria-label={`${label}-selector`}
              onFocus={() => this.addKeyDownListener(id)}
              onBlur={() => this.removeKeyDownListener(id)}
              role={multipleSelection ? 'checkbox' : 'radio'}
              aria-checked={selectedOptions.has(id)}
            >
              <Card
                label={label}
                imageUrl={imageUrl}
                youtubeId={youtubeId}
                selected={selectedOptions.has(id)}
                onClick={() => this.getCardClickHandler(id)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

CardSelectInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string.isRequired,
      imageUrl: PropTypes.string,
      youtubeId: PropTypes.string,
    })
  ).isRequired,
  initialValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  multipleSelection: PropTypes.bool,
  onChange: PropTypes.func,
};

CardSelectInput.defaultProps = {
  initialValue: [],
  multipleSelection: false,
  onChange: null,
};

export default CardSelectInput;
