/* eslint-disable react/prop-types */
// Libraries
import React, { forwardRef } from 'react';
import classNames from 'classnames';

const Input = forwardRef(({ className, ariaLabel, ...props }, ref) => (
  <input
    type="text"
    aria-label={ariaLabel}
    className={classNames(className, 'input is-medium')}
    {...props}
    ref={ref}
  />
));

export default Input;
