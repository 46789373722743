// Libraries
import React, { PureComponent, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import RadioGroup from 'common/components/RadioGroup';
import Input from 'common/components/Input';

// Enumerables
import InputFormat from '../../../enums/InputFormat';

export class RadioSelectInput extends PureComponent {
  textChoiceInputRef = createRef();

  constructor(props) {
    super(props);

    const { options } = props;
    const textChoiceIndex = options.findIndex(({ format }) => format === InputFormat.TEXT_CHOICE);
    let textChoiceOptionId = null;

    if (textChoiceIndex !== -1) {
      const textChoiceOption = options.splice(textChoiceIndex, 1)[0];
      // Move text choice option to the end
      options.push(textChoiceOption);
      textChoiceOptionId = textChoiceOption.id;
    }

    this.state = {
      options,
      textChoiceOptionId,
      selectedOptionId: props.initialValue,
      textChoiceValue: props.initialTextChoiceValue,
    };
  }

  fireOnChange = () => {
    const { onChange } = this.props;
    const { selectedOptionId, textChoiceOptionId, textChoiceValue } = this.state;

    if (onChange) {
      onChange(selectedOptionId, selectedOptionId === textChoiceOptionId ? textChoiceValue : true);
    }
  };

  handleChange = (selectedOptionId) => {
    this.setState({ selectedOptionId }, () => {
      this.fireOnChange();

      if (this.textChoiceInputRef.current) {
        this.textChoiceInputRef.current.focus();
      }
    });
  };

  handleTextChoiceChange = ({ target: { value: textChoiceValue } }) => {
    this.setState({ textChoiceValue }, this.fireOnChange);
  };

  render() {
    const { options, selectedOptionId, textChoiceOptionId, textChoiceValue } = this.state;
    // eslint-disable-next-line react/prop-types
    const { t } = this.props;
    return (
      <div className="radio-select-input">
        <RadioGroup
          options={options}
          initialValue={selectedOptionId}
          onChange={this.handleChange}
        />
        {textChoiceOptionId && (
          <Input
            value={textChoiceValue}
            onChange={this.handleTextChoiceChange}
            disabled={textChoiceOptionId !== selectedOptionId}
            ref={this.textChoiceInputRef}
            ariaLabel={t('Other (please specify)')}
          />
        )}
      </div>
    );
  }
}

RadioSelectInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string.isRequired,
      format: PropTypes.string.isRequired,
    })
  ).isRequired,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  initialTextChoiceValue: PropTypes.string,
  onChange: PropTypes.func,
};

RadioSelectInput.defaultProps = {
  initialValue: null,
  initialTextChoiceValue: '',
  onChange: null,
};

export default withTranslation()(RadioSelectInput);
