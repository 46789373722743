// Libraries
import React from 'react';
import classNames from 'classnames';

const Radio = ({ className, type, checked, ...props }) => (
  <input
    tabIndex={0}
    aria-checked={checked}
    checked={checked}
    className={classNames(className, 'radio__control-input')}
    type="radio"
    {...props}
  />
);

export default Radio;
