// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const TextAreaInput = ({
  id,
  name,
  label,
  register,
  validations,
  renderHelpItems,
  error,
  placeholder,
}) => {
  const { t } = useTranslation();

  return (
    <div className="input-section">
      <label className="label is-primary-color" htmlFor={id}>
        {label}
        {validations.required ? ' *' : ''}
      </label>
      <textarea
        id={id}
        aria-label={label}
        className={classNames('textarea input is-medium', { 'is-danger': error })}
        placeholder={placeholder}
        {...register(name, validations)}
      />
      {renderHelpItems()}
      {error && <span className="input-error">{t(error.message)}</span>}
    </div>
  );
};

TextAreaInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  validations: PropTypes.objectOf(PropTypes.any),
  renderHelpItems: PropTypes.func,
  error: PropTypes.shape({ message: PropTypes.string.isRequired }),
  placeholder: PropTypes.string,
};

TextAreaInput.defaultProps = {
  validations: {},
  renderHelpItems: () => {},
  error: null,
  placeholder: '',
};

export default TextAreaInput;
