// Libraries
import React from 'react';
import PropTypes from 'prop-types';

const YoutubeEmbedder = ({ id }) => (
  <div className="youtube-embedder">
    <div className="video-embedder">
      <iframe
        title="Youtube video"
        src={`https://www.youtube.com/embed/${id}?rel=0`}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  </div>
);

YoutubeEmbedder.propTypes = {
  id: PropTypes.string.isRequired,
};

export default YoutubeEmbedder;
