import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Option from './Option';

/**
 * Text options selector component.
 * Can be used both as radio buttons or checkboxes (single / multi selection).
 */
class ButtonSelectInput extends Component {
  static propTypes = {
    /**
     * Array of options.
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * ID used to uniquely identify the option.
         */
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        /**
         * Label to show in option's card.
         */
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    /**
     * (Optional) Initial selected option.
     */
    initialValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    /**
     * (Optional) Allow multiple selections. Defaults to false.
     */
    multipleSelection: PropTypes.bool,
    /**
     * (Optional) Callback to run when selection is changed.
     * Array of selected option ID's is passed to callback as first argument.
     */
    onChange: PropTypes.func,
  };

  static defaultProps = {
    initialValue: [],
    multipleSelection: false,
    onChange: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedOptions: new Set(props.initialValue),
    };
  }

  getOptionClickHandler = (id) => () => {
    const { multipleSelection, onChange } = this.props;
    const { selectedOptions } = this.state;
    const isSelected = selectedOptions.has(id);

    if (!multipleSelection) {
      selectedOptions.clear();
    }

    if (isSelected) {
      selectedOptions.delete(id);
    } else {
      selectedOptions.add(id);
    }

    const newSelectedOptions = new Set(selectedOptions);

    this.setState({
      selectedOptions: newSelectedOptions,
    });

    if (onChange) {
      onChange([...newSelectedOptions]);
    }
  };

  render() {
    const { options } = this.props;
    const { selectedOptions } = this.state;

    return (
      <div className="select-input" data-test="select-input" role="group" tabIndex={0}>
        {options.map(({ id, label }) => (
          <Option
            key={id}
            id={id}
            label={label}
            selected={selectedOptions.has(id)}
            onClick={this.getOptionClickHandler(id)}
          />
        ))}
      </div>
    );
  }
}

export default ButtonSelectInput;
